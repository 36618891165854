// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TruckTease from './TruckTease';

// Actions
import featuredTruckActions from '../../actions/featuredTruckActions';

// Selectors
import { getAllFeaturedTrucks } from '../../selectors';

class FeaturedTrucks extends Component {
  constructor(props, _railsContext) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchFeaturedTrucks();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const noCurrentTrucks = (!this.props.featuredTrucks || this.props.featuredTrucks.length === 0);
    const nextHasTrucks = (nextProps.featuredTrucks && nextProps.featuredTrucks.length > 0);
    if (noCurrentTrucks && nextHasTrucks) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { featuredTrucks } = this.props;

    if (!featuredTrucks || featuredTrucks.length === 0) return null;

    const trucks = featuredTrucks.map((truck) => {
      return (
        <div
          key={`${truck.id}-featured`}
          className="u-grid-col u-grid-sm-half u-grid-md-third u-mb1"
        >
          <TruckTease truck={truck} />
        </div>
      );
    });

    return (
      <div className="u-grid-row">
        {trucks}
      </div>
    );
  }
}

FeaturedTrucks.propTypes = {
  featuredTrucks: PropTypes.array,
  fetchFeaturedTrucks: PropTypes.func,
};

function mapStateToProps(state, props) {
  return {
    featuredTrucks: getAllFeaturedTrucks(state, props),
    ui: state.ui
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFeaturedTrucks(options) {
      dispatch(featuredTruckActions.fetchIfNeeded({ featured: true, page_size: 3 }));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedTrucks);
