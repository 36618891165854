// NPM Requirements
import { normalize } from 'normalizr';
import _ from 'lodash';

// Misc Requirements
import API from '../modules/api';
import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { displayError } from './errorActions';
import { truckSchema, truckListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('trucks', 'truck');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'truck',
  listName: 'trucks',
  resourceSchema: truckSchema,
  listSchema: truckListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {
  updateMenu(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/trucks/${options.truckId}/update_menu`,
                              { truck: { menu: options.menu } });

      promise.then((response) => {
        const records = normalize(response, truckSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },

  updatePhotos(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/trucks/${options.truckId}/update_photos`,
                              { truck: { photos: options.photos }, delete_new_photo: options.delete_new_photo });

      promise.then((response) => {
        const records = normalize(response, truckSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },

  updateCreditCard(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/trucks/${options.truckId}/update_credit_card`,
                              { truck: { stripe_token: options.stripe_token } });

      promise.then((response) => {
        const records = normalize(response, truckSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.responseJSON, true));
        } else {
          let message = '';
          if (response.responseJSON && response.responseJSON.base) {
            message = response.responseJSON.base[0];
          }
          dispatch(displayError(500, message, true));
        }
      });

      return promise;
    };
  },

  subscribe(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/trucks/${options.truckId}/subscribe`,
                              { plan_id: options.planId });

      promise.then((response) => {
        const records = normalize(response, truckSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        let error = '';
        if (response.statusText) {
          error = response.statusText;
        }
        if (response.responseJSON && response.responseJSON.error) {
          error = response.responseJSON.error;
        }
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, error, true));
        } else {
          dispatch(displayError(500, error, true));
        }
      });

      return promise;
    };
  },

  syncGoogleCalendar(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.get(`/api/trucks/${options.truckId}/sync_calendar`);

      promise.then((response) => {
        const records = normalize(response, truckSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        let error = '';
        if (response.statusText) {
          error = response.statusText;
        }
        if (response.responseJSON && response.responseJSON.error) {
          error = response.responseJSON.error;
        }
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, error, true));
        } else {
          dispatch(displayError(500, error, true));
        }
      });

      return promise;
    };
  },

  getGoogleCalendarDetails(options) {
    return dispatch => {
      dispatch(baseActions.fetchStart());

      const promise = API.get(`/api/trucks/${options.truckId}/calendar_details`);

      promise.then((response) => {
        const records = normalize(response, truckSchema);
        dispatch(baseActions.fetchSuccess(records));
      });

      promise.catch((response) => {
        let error = '';
        if (response.statusText) {
          error = response.statusText;
        }
        if (response.responseJSON && response.responseJSON.error) {
          error = response.responseJSON.error;
        }
        dispatch(baseActions.fetchError(response));
        if (response.status === 404) {
          dispatch(displayError(404, error, true));
        } else {
          dispatch(displayError(500, error, true));
        }
      });

      return promise;
    };
  },

  fetchDeletionStatus(options) {
    return dispatch => {
      dispatch(baseActions.fetchStart());

      const promise = API.get(`/api/trucks/deletion_status?id=${options.id}`);

      promise.then((response) => {
        dispatch(baseActions.fetchSuccess(response));
      });

      promise.catch((response) => {
        let error = '';
        if (response.statusText) {
          error = response.statusText;
        }
        if (response.responseJSON && response.responseJSON.error) {
          error = response.responseJSON.error;
        }
        dispatch(baseActions.fetchError(response));
        if (response.status === 404) {
          dispatch(displayError(404, error, true));
        } else {
          dispatch(displayError(500, error, true));
        }
      });

      return promise;
    };
  },
};

// Merge Actions
const actions = _.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
