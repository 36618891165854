// NPM Requirements
import { normalize } from 'normalizr';
import _ from 'lodash';

// Misc Requirements
import API from '../modules/api';
import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { featuredTruckSchema, featuredTruckListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('featuredTrucks', 'featuredTruck');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'featured_truck',
  listName: 'featuredTrucks',
  apiEndpoint: 'trucks',
  resourceSchema: featuredTruckSchema,
  listSchema: featuredTruckListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {
};

// Merge Actions
const actions = _.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
