// NPM Requirements
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TruckTease from './TruckTease';

import styles from './Truck.module.postcss';

class TrucksGrid extends PureComponent {
  render() {
    const { trucks, showEvent, cityConfig } = this.props;

    let displayedTrucks = [];

    if (trucks) {
      displayedTrucks = _.map(trucks, (truck) => {
        return (
          <div key={truck.id} className={styles.TruckTease_wrapper + " u-grid-col u-grid-sm-half u-grid-md-third"}>
            <TruckTease
              showEvent={showEvent}
              truck={truck}
            />
          </div>
        );
      });
    }

    return (
      <div>
        {displayedTrucks.length === 0 &&
          <h3 className="u-textCenter u-mb4">
            No {cityConfig.vehicle_type.toLowerCase()}s found
          </h3>
        }
        {displayedTrucks.length > 0 &&
          <div className="u-grid-row">
            {displayedTrucks}
          </div>
        }
      </div>
    );
  }
}

TrucksGrid.propTypes = {
  trucks: PropTypes.array,
  showEvent: PropTypes.bool
};

export default TrucksGrid;
