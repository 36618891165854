// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import geolib from 'geolib';
import moment from 'moment-timezone';
import cn from 'classnames';
import SVG from 'react-inlinesvg';

import IsoMorphUtils from '../../modules/isoMorphUtils';
import RouteUtils from '../../modules/routeUtils';
import GoogleMapsUtils from '../../modules/googleMapsUtils';
import { isMobile, removeArrayItem } from '../../modules/helpers';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';
import locationActions from '../../actions/locationActions';
import foodCategoryActions from '../../actions/foodCategoryActions';

// Selectors
import { getAllLocations, getAllFoodCategories, getCityConfig } from '../../selectors';

// Components
import { SearchMap } from './Search.imports-loadable';
import PureLoader from '../shared/PureLoader';
import Meta from '../shared/Meta';
import MapFilters from './MapFilters';
import TrucksGrid from './TrucksGrid';
import FeaturedTrucks from './FeaturedTrucks';
import FilterList from './FilterList';
import Sidebar from './Sidebar';
import Trucks from './Trucks';

import styles from './Search.module.postcss';

const CITY_ZOOM = 11;
const NEIGHBORHOOD_ZOOM = 13;
const LOCATION_ZOOM = 13;

class Search extends Component {
  constructor(props, _railsext) {
    super(props);

    const match = props.computedMatch || props.match;

    this.defaultCenter = { lat: props.cityConfig.latitude, lng: props.cityConfig.longitude };
    this.zoom = match.params.id ? NEIGHBORHOOD_ZOOM : CITY_ZOOM;
    this.onGeolocate = this.onGeolocate.bind(this);
    this.onFilterToggle = this.onFilterToggle.bind(this);
    this.toggleSelectingDay = this.toggleSelectingDay.bind(this);
    this.toggleCurrentMode = this.toggleCurrentMode.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.onMapChange = this.onMapChange.bind(this);
    this.updateQueryParam = this.updateQueryParam.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.removeArrayFilter = this.removeArrayFilter.bind(this);
    this.setMapMode = this.setMapMode.bind(this);
    this.setListMode = this.setListMode.bind(this);
    this.onMapClickOut = this.onMapClickOut.bind(this);

    this.neighborhood_id = match.params.id;

    const params = RouteUtils.getQueryParams(props.location);

    const currentFilters = {
      day: params.day,
      vegetarian: params.vegetarian,
      vegan: params.vegan,
      paleo: params.paleo,
      gluten_free: params.gluten_free,
      location: params.location,
      query: params.query,
      food_categories: params.food_categories,
    }

    this.state = {
      center: this.defaultCenter,
      currentFilters: _.pickBy(currentFilters, _.identity),
      currentMode: 'list',
      selectingDay: false,
      zoom: this.zoom,
      filtersOpen: false,
      geolocating: false,
      ready: false || IsoMorphUtils.ready(props),
      mapHasLoaded: false,
      zoomControlPosition: null,
      mapBounds: {},
      sortedTrucks: [],
      isMapsLoaded: false,
      geocoder: null,
    };
  }

  componentDidMount() {
    if (this.state.currentFilters.day === 'today') {
      this.updateQueryParam({ day: 'today' });
    }

    this.props.fetchFoodCategoriesIfNeeded();
    this.getLocations();

    GoogleMapsUtils.importLibraries(['core', 'geocoding']).then((result) => {

      this.setState({
        geocoder: new result[1].Geocoder(),
        isMapsLoaded: true,
        zoomControlPosition: result[0].ControlPosition.RIGHT_TOP,
        ready: true,
      });

      const { location } = RouteUtils.getQueryParams(this.props.location);
      if (location) {
        this.updateCenterFromLocation(location);
      } else if (this.neighborhood_id) {
        this.updateCenterFromLocation(this.neighborhood_id, NEIGHBORHOOD_ZOOM);
      } else if (!isMobile()) {
        this.onGeolocate();
      }
    });

    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.locationDidChange(nextProps) && this.state.geocoder) {
      const { location } = RouteUtils.getQueryParams(nextProps.location);
      this.updateCenterFromLocation(location);
      this.neighborhood_id = null;
    }

    const params = this.getQueryParams(this.props.location);
    const nextParams = this.getQueryParams(nextProps.location);

    if (!_.isEqual(params, nextParams)) {
      this.setState({ currentFilters: nextParams });
    }

    const hasDay = !!params.day;
    const nextHasDay = !!nextParams.day;
    if (hasDay && !nextHasDay) {
      this.setState({ currentMode: 'list' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldGetLocations(prevState)) this.getLocations();
  }

  getQueryParams(location) {
    const params = RouteUtils.getQueryParams(location);
    return _.omit(params, 'page');
  }

  updateCenterFromLocation(location, zoom = LOCATION_ZOOM) {
    const { cityConfig } = this.props;
    const { geocoder, isMapsLoaded } = this.state;
    if (!geocoder || !isMapsLoaded) return;

    geocoder.geocode({
      address: location,
      bounds: new google.maps.LatLngBounds(
        new google.maps.LatLng({ lat: cityConfig.lat_bounds_1, lng: cityConfig.lng_bounds_1 }),
        new google.maps.LatLng({ lat: cityConfig.lat_bounds_2, lng: cityConfig.lng_bounds_2 }),
      ),
    }, (results, status) => {
      if (status === 'OK') {
        const result = results[0].geometry.location;
        const center = {
          lat: result.lat(),
          lng: result.lng(),
        };

        this.setState({ center, zoom });
      }
    });
  }

  updateQueryParam(params) {
    RouteUtils.updateQueryParam(
      this.props.history,
      this.props.location,
      params,
    );
  }

  onFilterToggle() {
    const { filtersOpen } = this.state;
    this.setState({
      filtersOpen: !filtersOpen,
      selectingDay: false
    });
  }

  onGeolocate(mobile = false) {
    this.setState({ geolocating: true });
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        geolocating: false,
        zoom: LOCATION_ZOOM,
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      });
    });

    if (!this.state.currentFilters.day && mobile) {
      this.updateQueryParam({ day: 'today' });
    }
  }

  onMapChange(map) {
    if (!map || !map.bounds || !map.bounds.sw || !map.bounds.sw.lat) {
      return;
    }

    this.setState({
      center: map.center,
      mapBounds: this.state.currentMode === 'map' ? _.mapValues(map.bounds, (v) => (
        _.mapKeys(v, (v, k) => k === 'lat' ? 'latitude' : 'longitude')
      )) : {},
      zoom: map.zoom
    }, () => {
      this.setState({ sortedTrucks: this.sortedTrucks() });
    });
  }

  removeFilter(key) {
    this.updateQueryParam({ [key]: null });
  }

  removeArrayFilter(arrayName, key) {
    let currentParams = RouteUtils.getQueryParams(this.props.location);
    let newParams = currentParams[arrayName];
    removeArrayItem(newParams, key);
    this.updateQueryParam({ [arrayName]: newParams });
  }

  getLocations() {
    const { currentFilters } = this.state;

    if (!currentFilters.day) {
      return null;
    }

    let options = {
      with_active_trucks: true,
      only_with_events: true,
    };

    if (currentFilters.food_categories) options.with_events_in_category = currentFilters.food_categories;
    if (currentFilters.vegetarian) options.vegetarian = true;
    if (currentFilters.vegan) options.vegan = true;
    if (currentFilters.gluten_free) options.gluten_free = true;
    if (currentFilters.paleo) options.paleo = true;
    if (currentFilters.accepts_credit_cards) options.accepts_credit_cards = true;
    if (currentFilters.query) options.query = currentFilters.query;
    if (this.neighborhood_id) options.neighborhood = this.neighborhood_id;

    switch (currentFilters.day) {
      case 'today':
        options.with_events_on_day =
          moment().hour(12)
                  .minute(0)
                  .second(0)
                  .millisecond(0)
                  .tz('America/Los_Angeles')
                  .format();
        break;
      case 'tomorrow':
        options.with_events_on_day =
          moment().hour(12)
                  .minute(0)
                  .second(0)
                  .millisecond(0)
                  .add(1, 'day')
                  .tz('America/Los_Angeles')
                  .format();
        break;
      case null:
      case undefined:
        options = _.omit(options, ['with_events_on_day']);
        break;
      default:
        options.with_events_on_day =
          moment(currentFilters.day).tz('America/Los_Angeles').format();
    }

    this.props.fetchLocations(options).then(() => {
      this.setState({ sortedTrucks: this.sortedTrucks() });
    });
  }

  locationDidChange(nextProps) {
    const oldParams = RouteUtils.getQueryParams(this.props.location);
    const newParams = RouteUtils.getQueryParams(nextProps.location);

    return newParams.location && newParams.location !== oldParams.location;
  }

  shouldGetLocations(prevState) {
    const { currentFilters } = this.state;
    const { currentFilters: prevCurrentFilters } = prevState;

    if (!currentFilters) return false;

    if (!currentFilters.day) return false;

    if (!_.isEqual(currentFilters, prevCurrentFilters)) {
      return true;
    }

    return false;
  }

  sortedTrucks() {
    const { locations } = this.props;

    const center = {
      latitude: this.state.center.lat,
      longitude: this.state.center.lng
    };

    let sortedLocations = _.filter(locations, (location) => {
      return location.latitude && location.longitude;
    })
    sortedLocations = geolib.orderByDistance(center, sortedLocations);

    if (!_.isEmpty(this.state.mapBounds)) {
      sortedLocations = _.filter(sortedLocations, ({ latitude, longitude }) => {
        return geolib.isPointInside({ latitude, longitude }, [
          this.state.mapBounds.nw,
          this.state.mapBounds.ne,
          this.state.mapBounds.se,
          this.state.mapBounds.sw,
        ]);
      });
    }

    let allTrucks = _.flatMap(sortedLocations, ({ events, key }) => {
      return _.flatMap(events, ({ trucks, ...event }) => {
        return _.map(trucks, (truck) => ({
          event,
          location: locations[key],
          ...truck,
        }));
      });
    });

    if (this.state.currentFilters.query) {
      const query = this.state.currentFilters.query.toLowerCase();
      const containsQuery = (v) => v.toLowerCase().indexOf(query) > -1;

      allTrucks = _.filter(allTrucks, ({ food_categories, name }) => {
        return containsQuery(name) || _.some(food_categories, ({ slug }) => containsQuery(slug));
      });
    }

    return _.uniqBy(allTrucks, 'id');
  }

  toggleSelectingDay(selectingDay = null) {
    this.setState({
      selectingDay: selectingDay || !this.state.selectingDay
    });
  }

  toggleCurrentMode() {
    const newMode = this.state.currentMode === 'map' ? 'list' : 'map';

    this.setState({
      currentMode: newMode,
      mapBounds: {},
      mapHasLoaded: this.state.mapHasLoaded || newMode === 'map',
    });
  }

  setMapMode() {
    this.setState({
      currentMode: 'map',
      selectingDay: false,
      filtersOpen: false,
      mapHasLoaded: true,
    });

    if (!this.state.currentFilters.day) {
      this.updateQueryParam({ day: 'today' });
    }
  }

  setListMode() {
    this.setState({
      currentMode: 'list',
      selectingDay: false,
      filtersOpen: false
    });
  }

  onMapClickOut() {
    this.setState({ filtersOpen: false, selectingDay: false });
  }

  render() {
    if (!this.state.ready) return null;
    const { loading, cityConfig } = this.props;
    const { currentMode, filtersOpen, selectingDay, currentFilters, sortedTrucks } = this.state;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    let dayIsSet = true;
    if (!currentFilters.day) {
      dayIsSet = false;
    }

    let title = "Search - " + cityConfig.site_title;
    let description;
    let header;
    if (currentFilters.location) {
      const trucksString = sortedTrucks.length > 1 ? _.map(sortedTrucks, _.iteratee('name')).join(` food ${vehicleType}, '`) + ` food ${vehicleType}` : '';
      const location = _.startCase(currentFilters.location.replace(', USA',''));
      const updateDate = moment().format('MMMM YYYY');

      if (currentFilters.query) {
        const query = _.startCase(currentFilters.query);
        title = `The Top 10 Best ${query} Food ${cityConfig.vehicle_type}s near ${location} - Last Updated ${updateDate} - Food ${cityConfig.vehicle_type} Schedule`;
        description = `Best ${query} food ${vehicleType}s in ${location} - ${trucksString}`;
        header = `The Top 10 Best ${query} Food ${cityConfig.vehicle_type}s Near ${location}`;
      } else {
        title = `The Top 10 Best Food ${cityConfig.vehicle_type}s Near ${location} - Last Updated ${updateDate} - Food ${cityConfig.vehicle_type} Schedule`;
        description = `Best Food ${cityConfig.vehicle_type}s in ${location} - ${trucksString}`;
        header = `The Best Food ${cityConfig.vehicle_type}s Near ${location}`;
      }
    } else if (currentFilters.query) {
      const query = _.startCase(currentFilters.query);
      const updateDate = moment().format('MMMM YYYY');
      title = `The Top 10 Best ${query} Food ${cityConfig.vehicle_type}s Near Me - ${updateDate} : Find Nearby ${query} Food ${cityConfig.vehicle_type} Schedule`;
      header = `The Top 10 Best ${query} Food ${cityConfig.vehicle_type}s Near Me`;
    }

    return (
      <div className="Container">
        <Meta
          title={title}
          description={description}
        />
        <div className={styles.SecondaryHeader__MapFilters + " SecondaryHeader"}>
          <MapFilters
            currentFilters={currentFilters}
            foodCategories={this.props.foodCategories}
            currentMode={currentMode}
            history={this.props.history}
            location={this.props.location}
            mapDisabled={!dayIsSet}
            onClickOut={this.onMapClickOut}
            // ref={(c) => { this.mapFilter = c; }}
            selectingDay={selectingDay}
            toggleCurrentMode={this.toggleCurrentMode}
            toggleSelectingDay={this.toggleSelectingDay}
            updateQueryParam={this.updateQueryParam}
            visible={filtersOpen}
          />
        </div>
        <div
          className={cn({
            [styles.MapWithList]: true,
            [styles.MapWithList__list]: (currentMode === 'list'),
            [styles.MapWithList__map]: (currentMode === 'map'),
            [styles.MapWithList__filtersOpen]: filtersOpen,
          })}
        >
          <div className={styles.MapWithList_modeSelect}>

            <button
              onClick={() => this.onGeolocate(true)}
              className={styles.ModeButton}
            >
              <span>Near me</span>
              <SVG src='/static/images/icons/near-me.svg'/>
            </button>

            {currentMode === 'map' &&
              <button
                className={styles.ModeButton}
                onClick={this.setListMode}
              >
                <span>List</span>
                <SVG src='/static/images/icons/list-view.svg'/>
              </button>
            }

            {currentMode === 'list' &&
              <button
                className={styles.ModeButton}
                onClick={this.setMapMode}
              >
                <span>Map</span>
                <SVG src='/static/images/icons/map-view.svg'/>
              </button>
            }

            <button
              className={cn({
                [styles.ModeButton]: true,
                [styles.ModeButton__active]: filtersOpen
              })}
              onClick={this.onFilterToggle}
            >
              <span>Filters</span>
              <SVG src='/static/images/icons/filter.svg'/>
            </button>
          </div>

          <div className={styles.MapWithList_list}>
            { header &&
              <h1>{header}</h1>
            }
            <div className={styles.MapWithList_innerList}>
              <h4>Sponsored Results</h4>
              <FeaturedTrucks />

              <FilterList
                filters={currentFilters}
                foodCategories={this.props.foodCategories}
                history={this.props.history}
                location={this.props.location}
                removeFilter={this.removeFilter}
                removeArrayFilter={this.removeArrayFilter}
              />

              <PureLoader entities="location" actions="fetch" loading={loading} padded center>

                {dayIsSet && sortedTrucks.length > 0 && (
                  <TrucksGrid
                    trucks={sortedTrucks}
                    cityConfig={cityConfig}
                  />
                )}

                {dayIsSet && sortedTrucks.length === 0 && (
                  <div className="u-textCenter u-mt3 u-mb2">
                    <h3>No open food {vehicleType}s within the current map area</h3>
                    <p>Try using different or fewer keywords, change the date, or move the map and redo your search.</p>
                    <button
                      className="Button"
                      onClick={() => this.updateQueryParam({ day: null })}
                    >
                      View all {vehicleType}s
                    </button>
                  </div>
                )}

                {!dayIsSet && (
                  <Trucks
                    currentFilters={this.state.currentFilters}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                    cityConfig={cityConfig}
                  />
                )}

              </PureLoader>
            </div>
          </div>

          <div className={styles.MapWithList_map}>
            <div className={styles.MapWithList_sidebar}>
              <Sidebar hideAd />
            </div>
            <SearchMap
              fallback={<PureLoader/>}
              center={this.state.center}
              geolocating={this.geolocating}
              history={this.props.history}
              locations={this.props.locations}
              mapHasLoaded={this.state.mapHasLoaded}
              mapOptions={{
                fullscreenControl: false,
                zoomControlOptions: {
                  position: this.state.zoomControlPosition,
                },
              }}
              onMapChange={this.onMapChange}
              onGeolocate={this.onGeolocate}
              zoom={this.state.zoom}
            />
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  clearLocations: PropTypes.func,
  clearServerRenderedPath: PropTypes.func,
  computedMatch: PropTypes.object,
  fetchLocations: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.object,
  location: PropTypes.object,
  locations: PropTypes.array,
  match: PropTypes.object,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    locations: getAllLocations(state),
    foodCategories: getAllFoodCategories(state, props),
    loading: state.ui.loading,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLocations(options) {
      const defaultOptions = {
        include_events: true,
        include_trucks: true,
      };

      const mergedOptions = _.merge({}, defaultOptions, options);
      return dispatch(locationActions.fetch(mergedOptions));
    },
    clearLocations() {
      return dispatch(locationActions.clear());
    },
    fetchFoodCategoriesIfNeeded(options) {
      return dispatch(foodCategoryActions.fetchIfNeeded(options));
    },
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
